<template>
  <div class="" style="width: 100%;">
    <v-row>
      <v-col>
        <v-container fluid>
          <v-row>
            <v-col class="d-flex flex-row align-center">
              <h1>Hire Purchase</h1>
              <!-- <v-btn
                v-if="isAllowed('hirepurchase', 'c')"
                class="mx-2"
                fab
                small
                dark
                color="info"
                @click="openCreateDialog"
              >
                <v-icon dark>
                  mdi-plus
                </v-icon>
              </v-btn> -->
              <v-progress-circular
                  indeterminate
                  color="green"
                  v-if="loader"
                  style="margin-left: 10px;"
              ></v-progress-circular>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-card>
                <v-card-title>
                  <v-text-field
                      v-model="options.search"
                      label="Search"
                      single-line
                      hide-details
                  ></v-text-field>
                  <v-btn color="info" fab x-small><v-icon>mdi-magnify</v-icon></v-btn>
                </v-card-title>
                <v-data-table
                    :headers="headers"
                    :items="allData"
                    :options.sync="options"
                    :server-items-length="pagination.dataCount"
                    :loading="pagination.loading"
                    :items-per-page="-1"
                    class="elevation-1"
                    :footer-props="{
                      itemsPerPageOptions: pagination.pageSizeOptions
                    }"
                    @click:row="rowClick"
                    >
                    <template v-slot:item.customerName="{ item }">
                      <span>{{ item.Order.Customer.name }}</span>
                    </template>
                    <template v-slot:item.phone="{ item }">
                      <span>{{ item.Order.Customer.phone }}</span>
                    </template>
                    <template v-slot:item.order="{ item }">
                      <span>{{ item.Order.id }}</span>
                    </template>
                    <template v-slot:item.status="{ item }">
                      <v-chip :color="utils.getHPStatusColor(item.status)">{{utils.parseHPStatus(item.status)}}</v-chip>
                    </template>
                </v-data-table>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>

    <v-dialog v-model="createDialog.isOpen">
      <v-card>
        <v-card-title class="text-h5">
          Confirm Create New HP Agreement
        </v-card-title>
        <v-card-actions>
          <v-btn class="primary" text :loading="createDialog.isLoading" @click="confirmCreate">Create</v-btn>
          <v-btn class="error" text @click="closeCreateDialog">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackObj.state" :timeout="3000" :color="snackObj.color">
      {{ snackObj.text }}
      <template v-slot:action="{ attrs }">
        <v-btn v-bind="attrs" text @click="snackObj.state = false">Close</v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
  import axios from 'axios';
  import {mapGetters} from "vuex";
  import utils  from "../../plugins/helpers"
  export default {
    data () {
      return {
        utils: utils,
        deleteDialog: false,
        deleteConfirmed: true,
        loader: true,

        createDialog: {
          isOpen: false,
          isLoading: false,
          data: {}
        },

        snackObj: {
          state: false,
          color: '',
          text: ''
        },

        max25chars: v => v.length <= 25 || 'Input too long!',
        headers: [
          {
            text: 'ID',
            align: 'start',
            sortable: true,
            value: 'id',
          },
          { text: 'Status', value: 'status' },
          { text: 'Name', value: 'customerName' },
          { text: 'Phone', value: 'phone' },
          { text: 'Order ID', value: 'order' },
        ],
        pagination: {
          loading: false,
          dataCount: 0,
          pageSizeOptions: [5, 10, 15, 30, -1]
        },
        options: {
          search: "",
          sortBy: ['id'],
          sortDesc: [false],
          page: 1,
          itemsPerPage: 15
        },
        updatingRoute: false,
        init: false,

        editMode: false,

        existingData: [],
        existingDialog: false,
        dialogLoading: false,

        data: {},
        allData: [],
        singular: "HP",
        singularLower: "hp",
        plural: "hp",
        pluralLower: "hp"
      }
    },
    async created(){
      try{
        //used for pagination
        let oldQuery = this.getURLQuery();
        this.options.page = oldQuery.page || 1;
        this.options.itemsPerPage = oldQuery.limit || 15
        this.options.sortBy = [oldQuery.sort || "id"]
        this.options.sortDesc = [oldQuery.order || false]
        this.options.search = oldQuery.search || ""
        //used for pagination end
      }
      catch (error) {
        console.error(error)
        this.snack(error.msg || error.msg?.message || error, "error");
      }
    },
    async mounted(){
      this.init = true;

      this.pagination.pageSizeOptions = this.getGlobalValue("paginationPageLimitsGeneral")?.split(",").map(x => parseInt(x)) || this.pagination.pageSizeOptions;

      await this.getAllData();
      this.init = false;
    },
    computed: {
      ...mapGetters(['getEndpoint', "isAllowed", 'getGlobalValue'])
    },
    watch: {
      options: {
        async handler () {
          await this.getAllData();
        },
        deep: true,
      },
      $route: {
        handler: async function (current) {
          //used for pagination
          if (this.updatingRoute) return;
          let oldQuery = this.getURLQuery(current.query);
          this.options.page = oldQuery.page || 1;
          this.options.itemsPerPage = oldQuery.limit || 15
          this.options.sortBy = [oldQuery.sort || "id"]
          this.options.sortDesc = [oldQuery.order || true]
          this.options.search = oldQuery.search || ""
          //used for pagination end include full watcher
        },
        deep: true
      }
    },
    methods: {
      //used for pagination
      getURLQuery(custom=null){
        let oldQuery = custom? {...custom}: {...this.$route.query};
        if(oldQuery.limit) oldQuery.limit = parseInt(oldQuery.limit);
        if(oldQuery.page) oldQuery.page = parseInt(oldQuery.page);
        if(oldQuery.order) oldQuery.order = oldQuery.order==='true';
        console.log(oldQuery)
        return oldQuery;
      },
      openCreateDialog(){
        this.createDialog.isOpen = true
      },
      closeCreateDialog(){
        this.createDialog = {
          isOpen: false,
          isLoading: false,
          data: {}
        }
      },
      //used for pagination end
      rowClick(row){
        this.$router.push({ path: `/hirepurchase/view/${row.id}`})
      },
      snack(text, color=""){
        this.snackObj.text = text;
        this.snackObj.state = true;
        this.snackObj.color = color;
      },
      async create(){
        try {
          let res = await axios.post(`${this.getEndpoint}/api/${this.pluralLower}`, this.data)
          if(res.data.error) throw res.data.error

          if(res.data.data.created){
            this.snack(`${this.singular} Created 🎉`, "success");
            this.data={};
            await this.getAllData();
          }
          else{
            this.existingDialog = true;
            this.existingData = res.data.data.data;
          }
        } catch (error) {
          console.error(error)
          this.snack(error.msg || error.msg?.message || error, "error");
        }
      },
      async confirmCreate(){
        try {
          throw "This should not even be used"
          // this.createDialog.isLoading = true;
          // let res = await axios.post(`${this.getEndpoint}/api/hp/`, {})
          // if(res.data.error) throw res.data.error

          // this.snack(`Hire Purchase Agreement Created 🎉`, "success");
          
          // await this.router.push({path: `/hirePurchase/${res.data.data.id}`})

        } catch (error) {
          console.error(error)
          this.snack(error.msg || error.msg?.message || error, "error");
        }
        finally {
          this.closeCreateDialog();
        }
      },
      closeDialog(){
        this.existingDialog = false;
        this.existingData = [];
        this.dialogLoading = false;
      },
      async getAllData(){
        try {
          //used for pagination
          this.pagination.loading = true;
          let paginationData = {
            page: this.options.page,
            limit: this.options.itemsPerPage,
            sort: (this.options.sortBy.length>0)?this.options.sortBy[0]:"id",
            order: this.options.sortDesc[0],
            search: this.options.search
          }
          let uriFields = Object.keys(paginationData).map(x => {
            return x + "=" + (paginationData[x]!==undefined?encodeURIComponent(paginationData[x]):'')
          }).join("&");
          //used for pagination end

          let res = await axios.get(`${this.getEndpoint}/api/${this.pluralLower}/tableDataPaginated?${uriFields}`)
          if(res.data.error) throw res.data.error
          this.allData = res.data.data

          //used for pagination
          this.pagination.dataCount = res.data.total
          let shouldUpdate = false;
          let oldQuery = this.getURLQuery();
          let newQuery = {...paginationData};
          if(Object.keys(newQuery).length!==Object.keys(oldQuery).length) shouldUpdate = true;

          if(!shouldUpdate){
            for(let key of Object.keys(newQuery)){
              if(shouldUpdate) break;
              if(Array.isArray(newQuery[key])){
                if(newQuery[key].length!==oldQuery[key].length){
                  shouldUpdate = true;
                  break;
                }
                for(let i=0;i<newQuery[key].length-1;i++){
                  if(newQuery[key][i]!==oldQuery[key][i]){
                    shouldUpdate = true;
                    break;
                  }
                }
              }
              else if(newQuery[key]!==oldQuery[key]){
                shouldUpdate = true;
              }
            }
          }


          if(shouldUpdate){
            this.updatingRoute = true;
            if(this.init){
              if(this.$route.path===`/${this.pluralLower}`) await this.$router.replace({path: `/${this.pluralLower}`, query: newQuery});
            }
            else{
              if(this.$route.path===`/${this.pluralLower}`) await this.$router.push({path: `/${this.pluralLower}`, query: newQuery});
            }
            this.updatingRoute = false;
          }
          //used for pagination end
        } catch (error) {
          console.error(error);
        } finally {
          this.pagination.loading = false;
          this.loader = false;
        }
      }
    }
  }
</script>
